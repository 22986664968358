import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';




@Injectable()
export class Globalvars {

  api_url: any = 'http://localhost:8002/api/';


  constructor(public router: Router, private navCtrl: NavController, public toastController: ToastController) { }

  goto_page(page, payload = null){

    let navigationExtras: NavigationExtras = {
      state: {
        payload: payload
      }
    };

    this.router.navigate([page], navigationExtras);

  }

  go_back(){
    this.navCtrl.pop();
  }

  async presentToast(res_msg) {
    const toast = await this.toastController.create({
      message: res_msg.message,
      duration: 2000
    });
    toast.present();
  }
  

  }